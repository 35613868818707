import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import { getAuthHeader } from "./Blog";
import { useNavigate } from "react-router-dom";

const ApplicationsPage = () => {
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const limit = 10;

  useEffect(() => {
    const fetchCompanies = async () => {
      setIsLoading(true); // Start loading
      try {
        const response = await axios.get(
          `https://api.instacorp.ae/api/admin/data/companies?page=${currentPage}&limit=${limit}`,
          {
            headers: getAuthHeader(),
          }
        );
        setCompanies(response.data.companies);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
      setIsLoading(false); // End loading
    };

    fetchCompanies();
  }, [currentPage]);

  // Function to format company activities for display
  const formatActivities = (activities) => {
    return (
      <ul>
        {activities?.map((activity, index) => (
          <li key={index} className="text-sm text-gray-500">
            {activity["Activity Name"]}
          </li>
        ))}
      </ul>
    );
  };

  const navigate = useNavigate();

  const navigateToCompanyDetail = (companyId, company) => {
    navigate(`/applications/${companyId}`, { state: { company } });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric', month: 'long', day: 'numeric'
    }) + ' ' + date.toLocaleTimeString();
  };

  return (
    <div>
      <Header />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Applications
            </h1>
          </div>
        </div>
        <div className="mt-8 flow-root ">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Company Info
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Location
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Company Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Trade Zone
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Activities
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Office Type
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date Created
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Formation Stage
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {isLoading ? (
                    <tr>
                      <td colSpan="9" className="text-center py-10">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    companies.map((company) => (
                      <tr
                        key={company._id}
                        onClick={() =>
                          navigateToCompanyDetail(company._id, company)
                        }
                        style={{ cursor: "pointer" }} // Optional: Changes the cursor on hover
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {company.companyNames
                            ? company.companyNames["first"]['english']
                            : "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {company.companyDetails
                            ? company.companyDetails.location
                            : ""}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {company.companyDetails
                            ? company.companyDetails.typeOfCompany
                            : ""}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {company.companyDetails
                            ? company.companyDetails.typeOfTradeZone
                            : ""}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatActivities(company.companyActivities)}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {company.typeOfOffice
                            ? company.typeOfOffice.name
                            : ""}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(company.dateCreated) || "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
                              company.status === "in-progress"
                                ? "bg-blue-100 text-blue-800"
                                : company.status === "processing"
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                          >
                            {company.status || "N/A"}
                          </span>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex justify-between mt-4 mb-12">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
          >
            Previous
          </button>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsPage;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center space-x-1">
      {[...Array(totalPages).keys()].map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number + 1)}
          className={`px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md ${
            currentPage === number + 1 ? "bg-gray-200" : "hover:bg-gray-100"
          }`}
        >
          {number + 1}
        </button>
      ))}
    </div>
  );
};
