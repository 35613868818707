import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from "./Header";
import { getAuthHeader } from "./Blog";

const BlogPost = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://api.instacorp.ae/api/blog/${id}`, {
          headers: getAuthHeader(),
        });
        setBlog(response.data);
      } catch (error) {
        console.error('Fetch blog error:', error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <div className="text-center text-indigo-600">Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <article className="max-w-2xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-800 mb-3">{blog.title}</h1>
          <div className="text-gray-500 text-sm mb-6">
            Published on {new Date(blog.createdAt).toLocaleDateString()} by {blog.author}
          </div>
          {/* Reset any inherited styles */}
          <div className="prose lg:prose-xl max-w-none reset-styles" dangerouslySetInnerHTML={{ __html: blog.content }} />
        </article>
      </div>
    </div>
  );
};

export default BlogPost;