import React, { useState } from "react";

const ImageUploader = ({ onImageChange }) => {
  const [imagePreview, setImagePreview] = useState("");
  const [altText, setAltText] = useState(""); // State to hold the alt text

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageChange(file); 
    }
  };
  const handleAltTextChange = (e) => {
    setAltText(e.target.value); // Update the altText state
  };

  const clearImage = () => {
    setImagePreview("");
    setAltText("");
    onImageChange({ image: "", altText: "" });
  };

  return (
    <div className="flex flex-col items-center mt-32">
      {imagePreview && (
        <div className="mb-4">
          <img
            src={imagePreview}
            alt={altText}
            className="rounded-md h-auto max-w-xs"
          />
          <button onClick={clearImage}>Clear Image</button>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="mb-2"
      />
      <input
        type="text"
        value={altText}
        onChange={handleAltTextChange}
        placeholder="Enter image alt text"
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
      />
    </div>
  );
};

export default ImageUploader;
