import { useState } from "react";
import axios from "axios";
import {
    CheckCircleIcon
  } from "@heroicons/react/24/solid";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

export default function ImportCompany() {
  const [userId, setUserId] = useState(""); // New state for userId
  const [companyType, setCompanyType] = useState("Free Zone");
  const [companyName, setCompanyName] = useState("");
  const [shareholderName, setShareholderName] = useState("");
  const [leaseAgreement, setLeaseAgreement] = useState(null);
  const [leaseAgreementName, setLeaseAgreementName] = useState("");
  const [licenseDocument, setLicenseDocument] = useState(null);
  const [licenseDocumentName, setLicenseDocumentName] = useState("");
  const [certificateOfIncorporation, setCertificateOfIncorporation] =
    useState(null);
  const [certificateOfIncorporationName, setCertificateOfIncorporationName] =
    useState("");
  const [moaDocument, setMoaDocument] = useState(null);
  const [moaDocumentName, setMoaDocumentName] = useState("");
  const [authSignatoryPassport, setAuthSignatoryPassport] = useState(null);
  const [authSignatoryPassportName, setAuthSignatoryPassportName] =
    useState("");
  const [emiratesIdFront, setEmiratesIdFront] = useState(null);
  const [emiratesIdFrontName, setEmiratesIdFrontName] = useState("");
  const [emiratesIdBack, setEmiratesIdBack] = useState(null);
  const [emiratesIdBackName, setEmiratesIdBackName] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleFileSizeValidation = (file) => {
    const maxSize = 15 * 1024 * 1024;
    return file.size <= maxSize;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true);
    setErrorMessage("");

    // Validate required fields
    if (
      !userId ||
      !companyType ||
      !companyName ||
      !shareholderName ||
      !leaseAgreement ||
      !licenseDocument ||
      !certificateOfIncorporation ||
      !moaDocument ||
      !authSignatoryPassport ||
      !emiratesIdFront ||
      !emiratesIdBack ||
      !companyMobile ||
      !companyEmail
    ) {
      setErrorMessage("All fields are required. Please fill out all fields.");
      setIsSubmitting(false);
      return;
    }

    // Validate file sizes
    if (leaseAgreement && !handleFileSizeValidation(leaseAgreement)) {
      setErrorMessage(
        "Lease Agreement / Ejari exceeds the 15 MB file size limit."
      );
      setIsSubmitting(false);
      return;
    }
    if (licenseDocument && !handleFileSizeValidation(licenseDocument)) {
      setErrorMessage("License Document exceeds the 15 MB file size limit.");
      setIsSubmitting(false);
      return;
    }
    if (
      certificateOfIncorporation &&
      !handleFileSizeValidation(certificateOfIncorporation)
    ) {
      setErrorMessage(
        "Certificate of Incorporation exceeds the 15 MB file size limit."
      );
      setIsSubmitting(false);
      return;
    }
    if (moaDocument && !handleFileSizeValidation(moaDocument)) {
      setErrorMessage("MOA Document exceeds the 15 MB file size limit.");
      setIsSubmitting(false);
      return;
    }
    if (
      authSignatoryPassport &&
      !handleFileSizeValidation(authSignatoryPassport)
    ) {
      setErrorMessage(
        "Authorized Signatory Passport exceeds the 15 MB file size limit."
      );
      setIsSubmitting(false);
      return;
    }
    if (emiratesIdFront && !handleFileSizeValidation(emiratesIdFront)) {
      setErrorMessage("Emirates ID Front exceeds the 15 MB file size limit.");
      setIsSubmitting(false);
      return;
    }
    if (emiratesIdBack && !handleFileSizeValidation(emiratesIdBack)) {
      setErrorMessage("Emirates ID Back exceeds the 15 MB file size limit.");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId); // Include userId in the formData
    formData.append("companyType", companyType);
    formData.append("companyName", companyName);
    formData.append("shareholderName", shareholderName);
    formData.append("leaseAgreement", leaseAgreement);
    formData.append("licenseDocument", licenseDocument);
    formData.append("certificateOfIncorporation", certificateOfIncorporation);
    formData.append("moaDocument", moaDocument);
    formData.append("authSignatoryPassport", authSignatoryPassport);
    formData.append("emiratesIdFront", emiratesIdFront);
    formData.append("emiratesIdBack", emiratesIdBack);
    formData.append("companyMobile", companyMobile);
    formData.append("companyEmail", companyEmail);

    try {
      const jwtToken = localStorage.getItem("token");
      if (!jwtToken) {
        return;
      }
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "multipart/form-data",
      };

      const res = await axios.post(
        "https://api.instacorp.ae/api/company/admin/import-company",
        formData,
        { headers }
      );
      if(res.status === 201){
        navigate(`/companies`);
      }
    } catch (error) {
      if (error.response?.status === 413) {
        setErrorMessage(
          "File size too large. Please make sure each file is less than 15 MB."
        );
      } else {
        setErrorMessage(
          error.response?.data?.message ||
            "Failed to import company. Please try again."
        );
      }
      console.error("Failed to import company:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="mx-auto rounded-lg bg-white p-8 shadow-md">
        <h1 className="mb-6 text-xl font-bold text-gray-800">
          Import Existing Company
        </h1>
        <p className="mb-8 text-base text-gray-600">
          Only a General Manager, Shareholder or Authorized Signatory can import
          a company. Please fill out the form below and upload the necessary
          documents.
        </p>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* User Information Section */}
          <div className="rounded-lg bg-gray-50 p-6 shadow-sm">
            <h2 className="mb-4 text-lg font-semibold text-gray-800">
              User Information
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="userId"
                  className="block text-sm font-medium text-gray-700"
                >
                  User ID
                </label>
                <input
                  id="userId"
                  type="text"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  required
                  className="mt-1 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* Company Information Section */}
          <div className="rounded-lg bg-gray-50 p-6 shadow-sm">
            <h2 className="mb-4 text-lg font-semibold text-gray-800">
              Company Information
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company Name (As per the License)
                </label>
                <input
                  id="companyName"
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  className="mt-1 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="companyType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company Jurisdiction
                </label>
                <select
                  id="companyType"
                  value={companyType}
                  onChange={(e) => setCompanyType(e.target.value)}
                  required
                  className="mt-1 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="Free Zone">Free Zone</option>
                  <option value="Mainland">Mainland</option>
                </select>
              </div>
            </div>
          </div>

          {/* General Manager Information Section */}
          <div className="rounded-lg bg-gray-50 p-6 shadow-sm">
            <h2 className="mb-4 text-lg font-semibold text-gray-800">
              General Manager Information
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="shareholderName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Authorized Signatory Name (General Manager)
                </label>
                <input
                  id="shareholderName"
                  type="text"
                  value={shareholderName}
                  onChange={(e) => setShareholderName(e.target.value)}
                  placeholder="Enter authorized signatory name"
                  required
                  className="mt-1 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="companyMobile"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company Mobile Number
                </label>
                <input
                  id="companyMobile"
                  type="text"
                  value={companyMobile}
                  onChange={(e) => setCompanyMobile(e.target.value)}
                  placeholder="Enter company mobile number"
                  required
                  className="mt-1 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
              <div>
                <label
                  htmlFor="companyEmail"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company Email Address
                </label>
                <input
                  id="companyEmail"
                  type="email"
                  value={companyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  placeholder="Enter company email address"
                  required
                  className="mt-1 w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* Upload Documents Section */}
          <div className="rounded-lg bg-gray-50 p-6 shadow-sm">
            <h2 className="mb-4 text-lg font-semibold text-gray-800">
              Upload Documents
            </h2>
            <p className="mb-4 text-sm text-gray-600">
              Please ensure all documents are of the highest quality and clarity
              to avoid rejection by the authorities. Additionally, ensure each
              file is under 15 MB.
            </p>

            <div className="space-y-4">
              <div className="flex items-center">
                <label
                  htmlFor="licenseDocument"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  1. Trade License
                </label>
                <input
                  id="licenseDocument"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setLicenseDocument(e.target.files[0]);
                    setLicenseDocumentName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="licenseDocument"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {licenseDocumentName || "Choose File"}
                </label>
              </div>
              <div className="flex items-center">
                <label
                  htmlFor="leaseAgreement"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  2. Lease Agreement / Ejari
                </label>
                <input
                  id="leaseAgreement"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setLeaseAgreement(e.target.files[0]);
                    setLeaseAgreementName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="leaseAgreement"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {leaseAgreementName || "Choose File"}
                </label>
              </div>
              <div className="flex items-center">
                <label
                  htmlFor="certificateOfIncorporation"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  3. Certificate of Incorporation
                </label>
                <input
                  id="certificateOfIncorporation"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setCertificateOfIncorporation(e.target.files[0]);
                    setCertificateOfIncorporationName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="certificateOfIncorporation"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {certificateOfIncorporationName || "Choose File"}
                </label>
              </div>
              <div className="flex items-center">
                <label
                  htmlFor="moaDocument"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  4. Signed Memorandum of Association & Articles of Association
                  (MOA & AOA)
                </label>
                <input
                  id="moaDocument"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setMoaDocument(e.target.files[0]);
                    setMoaDocumentName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="moaDocument"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {moaDocumentName || "Choose File"}
                </label>
              </div>
              <div className="flex items-center">
                <label
                  htmlFor="authSignatoryPassport"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  5. Authorized Signatory Passport
                </label>
                <input
                  id="authSignatoryPassport"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setAuthSignatoryPassport(e.target.files[0]);
                    setAuthSignatoryPassportName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="authSignatoryPassport"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {authSignatoryPassportName || "Choose File"}
                </label>
              </div>
              <div className="flex items-center">
                <label
                  htmlFor="emiratesIdFront"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  6. Authorized Signatory Emirates ID (Front)
                </label>
                <input
                  id="emiratesIdFront"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setEmiratesIdFront(e.target.files[0]);
                    setEmiratesIdFrontName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="emiratesIdFront"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {emiratesIdFrontName || "Choose File"}
                </label>
              </div>
              <div className="flex items-center">
                <label
                  htmlFor="emiratesIdBack"
                  className="block flex-grow text-sm font-medium text-gray-700"
                >
                  7. Authorized Signatory Emirates ID (Back)
                </label>
                <input
                  id="emiratesIdBack"
                  type="file"
                  accept=".pdf,.jpg,.jpeg,.png"
                  onChange={(e) => {
                    setEmiratesIdBack(e.target.files[0]);
                    setEmiratesIdBackName(e.target.files[0].name);
                  }}
                  required
                  className="hidden"
                />
                <label
                  htmlFor="emiratesIdBack"
                  className="ml-3 inline-flex cursor-pointer items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {emiratesIdBackName || "Choose File"}
                </label>
              </div>
            </div>
          </div>

          {/* Error Message Display */}
          {errorMessage && (
            <div className="mb-4 rounded-md bg-red-100 p-4 text-sm text-red-800">
              {errorMessage}
            </div>
          )}

          {/* Submit Button */}
          <div className="flex justify-center pb-24">
            {" "}
            {/* Added pb-8 for padding */}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`mt-6 flex w-full sm:w-auto items-center justify-center rounded-full bg-black px-4 py-2 text-body text-white transition duration-150 ease-in-out hover:bg-primary-default disabled:bg-primary-default`}
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <CheckCircleIcon className="mr-3 h-5 w-5 animate-spin" />
                  Submitting...
                </div>
              ) : (
                <span className="align-middle">Submit</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
