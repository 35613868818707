import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

const TradeZonesPage = () => {
  const [tradeZones, setTradeZones] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newZoneData, setNewZoneData] = useState({ name: "", location: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const limit = 10;

  useEffect(() => {
    fetchTradeZones();
  }, [currentPage]);

  const fetchTradeZones = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.instacorp.ae/api/tradezones?page=${currentPage}&limit=${limit}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setTradeZones(response.data.tradeZones);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching trade zones:", error);
    }
    setIsLoading(false);
  };

  const handleAddZone = async () => {
    try {
      await axios.post(
        "https://api.instacorp.ae/api/tradezones",
        newZoneData,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setShowModal(false);
      fetchTradeZones();  // Refresh list after adding
    } catch (error) {
      console.error("Error adding new trade zone:", error);
    }
  };

  const handleDelete = async (zoneId) => {
    if (window.confirm("Are you sure you want to delete this trade zone?")) {
      try {
        await axios.delete(`https://api.instacorp.ae/api/tradezones/${zoneId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setTradeZones(tradeZones.filter(zone => zone._id !== zoneId));
      } catch (error) {
        console.error("Failed to delete trade zone:", error);
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-semibold text-gray-900">Trade Zones</h1>
        <button
          onClick={() => setShowModal(true)}
          className="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          Add New Trade Zone
        </button>
        <div className="mt-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td colSpan="3" className="text-center py-10">Loading...</td>
                  </tr>
                ) : tradeZones.map((zone) => (
                  <tr key={zone._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{zone.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{zone.location}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button onClick={() => navigate(`/edit-tradezone/${zone._id}`)} className="text-indigo-600 hover:text-indigo-900">
                        Edit
                      </button>
                      <button onClick={() => handleDelete(zone._id)} className="text-red-600 hover:text-red-900 ml-4">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {showModal && (
          <Modal
            newZoneData={newZoneData}
            setNewZoneData={setNewZoneData}
            handleAddZone={handleAddZone}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </div>
  );
};

const Modal = ({ newZoneData, setNewZoneData, handleAddZone, setShowModal }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div>
          <label className="block text-sm font-medium text-gray-700">Zone Name</label>
          <input
            type="text"
            value={newZoneData.name}
            onChange={e => setNewZoneData({ ...newZoneData, name: e.target.value })}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Location</label>
          <input
            type="text"
            value={newZoneData.location}
            onChange={e => setNewZoneData({ ...newZoneData, location: e.target.value })}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleAddZone}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Add Zone
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="ml-4 bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TradeZonesPage;
