import { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import {
  BuildingOfficeIcon,
  CheckIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  DocumentTextIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const services = {
  visa: {
    visaAssistanceInside: {
      title: "Visa Assistance (Inside country)",
      description:
        "Includes entry permit & residency approval, change status, medical test, and more.",
    },
    visaAssistanceOutside: {
      title: "Visa Assistance (Outside country)",
      description:
        "Services for entry permit, medical test, biometrics, and Emirates ID delivery.",
    },
  },
  poBox: {
    sharedPOBox: {
      title: "Shared P.O Box",
      description:
        "A cost-effective solution for handling your business correspondence.",
    },
  },
  banking: {
    wioBank: {
      title: "Wio Bank Service",
      description:
        "Banking services tailored for businesses, including account management.",
    },
  },
};

const CompanyDetailsPage = () => {
  const location = useLocation();
  const [company, setCompany] = useState(location.state.company);

  const extraActivityCost =
    company.companyActivities && company.companyActivities.length > 3
      ? (company.companyActivities.length - 3) * 1000
      : 0;

  // Update total price calculation

  const establishmentCard = company.visas?.establishmentCard || 0;
  const licenseFee = company.visas?.licenseFee || 0;
  const visaCount = company.visas?.visa || 0;

  // Calculate addon costs and extract visa assistance add-ons
  const addonCosts = Object.values(company.addons || {}).reduce(
    (total, addon) => total + addon.cost * addon.quantity,
    0
  );

  const totalPrice =
    establishmentCard + licenseFee + visaCount + extraActivityCost + addonCosts;

  return (
    <div className="bg-white text-gray-900">
      <Header />
      <div className="container mx-auto p-4">
        <h1 className="text-xl font-semibold">Company Details</h1>
        {company && (
          <div className="mt-4">
            {/* License Package Details */}
            <div className="mx-auto my-1 mt-4 space-y-2 rounded-md bg-slate-100 p-8">
              <p className="secondary-slate pb-6 text-body">
                License Package Proposal Number: INSTA
                {company._id.toUpperCase()}
              </p>

              <div className="flex items-center justify-between rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div>
                  <span className="text-body leading-6 text-primary-default">
                    License Type
                  </span>
                  <div className="mt-2">
                    <div className="text-body font-semibold text-primary-dark">
                      {company.licenseType.name}
                    </div>
                  </div>
                </div>
                <div>
                  <span className="text-body leading-6 text-primary-default">
                    Company Names
                  </span>
                  <ul className="mt-2 flex flex-wrap items-center gap-2">
                    {Object.entries(company.companyNames || {}).map(
                      ([key, name]) =>
                        name.trim() && (
                          <li key={key} className="text-body text-primary-dark">
                            <span className="inline-block rounded-full bg-primary-default px-3 py-1 text-black">
                              {name}
                            </span>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>

              <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                <div className="">
                  <span className="text-body leading-6 text-primary-default">
                    Company Information
                  </span>
                  <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className="flex items-center space-x-3">
                      <QuestionMarkCircleIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Company Type:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyDetails.typeOfCompany}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <MapPinIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Location:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyDetails.location}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <GlobeAltIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Trade Zone:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyDetails.typeOfTradeZone}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <InformationCircleIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Business Details:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.companyType}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3">
                      <BuildingOfficeIcon
                        className="h-6 w-6 flex-shrink-0 text-body text-primary-default"
                        aria-hidden="true"
                      />
                      <div className="text-body text-primary-dark">
                        Type Of Office:
                        <span className="text-body font-semibold text-primary-dark">
                          {company.typeOfOffice.description}
                        </span>
                      </div>
                    </div>
                    <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                      <div className="flex items-center">
                        <UserGroupIcon
                          className="mr-2 h-6 w-6 text-body text-primary-default"
                          aria-hidden="true"
                        />
                        <span className="text-body leading-6 text-primary-default">
                          Shareholders & Share Capital
                        </span>
                      </div>
                      <div>
                        <div className="mt-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                          {company.shareholderDetails.shareholders.map(
                            (shareholder, index) => (
                              <div
                                key={index}
                                className="rounded-lg border-2 border-primary-default bg-white p-4 shadow"
                              >
                                <div className="text-md font-semibold text-primary-default">
                                  {shareholder.name}
                                </div>
                                <div className="mt-2 text-sm text-primary-dark">
                                  Shares:{" "}
                                  <span className="text-sm">
                                    {shareholder.shares} shares
                                  </span>
                                </div>
                                <div className="mt-1 text-sm text-primary-dark">
                                  Address: {shareholder.addressLine1},{" "}
                                  {shareholder.cityState}, {shareholder.country}
                                </div>
                                <div className="mt-2 flex flex-wrap items-center gap-1">
                                  {company.shareholderDetails.directors.some(
                                    (director) => director.id === shareholder.id
                                  ) && (
                                    <span className="rounded-full bg-blue-100 px-2 py-1 text-xs text-blue-800">
                                      Director
                                    </span>
                                  )}
                                  {shareholder.id ===
                                    company.shareholderDetails.secretary.id && (
                                    <span className="rounded-full bg-green-100 px-2 py-1 text-xs text-green-800">
                                      Secretary
                                    </span>
                                  )}
                                  {shareholder.id ===
                                    company.shareholderDetails.generalManager
                                      .id && (
                                    <span className="rounded-full bg-red-100 px-2 py-1 text-xs text-red-800">
                                      General Manager
                                    </span>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                          <div className="flex items-center space-x-3">
                            <div>
                              <div className="text-body text-primary-dark">
                                Total Share Capital:
                                <span className="text-body font-semibold text-primary-dark">
                                  {" "}
                                  {company.shareholderDetails.shareCapital.totalValue.toLocaleString()}{" "}
                                  AED
                                </span>
                              </div>
                              <div className="text-body text-primary-dark">
                                Total Number of Shares:
                                <span className="text-body font-semibold text-primary-dark">
                                  {" "}
                                  {
                                    company.shareholderDetails.shareCapital
                                      .numberOfShares
                                  }
                                </span>
                              </div>
                              <div className="text-body text-primary-dark">
                                Value per Share:
                                <span className="text-body font-semibold text-primary-dark">
                                  {" "}
                                  {
                                    company.shareholderDetails.shareCapital
                                      .valuePerShare
                                  }{" "}
                                  AED
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                      <div className="flex items-center">
                        <UserIcon
                          className="mr-2 h-6 w-6 text-body text-primary-default"
                          aria-hidden="true"
                        />
                        <span className="text-body leading-6 text-primary-default">
                          License Package Details
                        </span>
                      </div>
                      <div className="mt-4">
                        <div
                          className={`relative col-span-1 h-[20rem] w-[24rem] transform cursor-pointer rounded-xl  bg-[#1C1C83] text-white shadow-sm
             
               ring-2 ring-blue-600 transition-transform duration-200
                
              `}
                        >
                          <div className="mt-2 flex h-full flex-col justify-between p-6">
                            <div className="flex items-center justify-between">
                              <span className="text-3xl font-semibold">
                                AED {licenseFee.toLocaleString()}
                              </span>
                            </div>
                            <span className="mt-2 text-body ">
                              {visaCount} Visa License
                            </span>
                            <ul className="mb-6 space-y-2 text-body">
                              <li>
                                <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                                  <CheckIcon className="h-4 w-4" />
                                </span>{" "}
                                Free Virtual Office
                              </li>
                              <li>
                                <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                                  <CheckIcon className="h-4 w-4" />
                                </span>{" "}
                                Visa Provided with License: {visaCount}
                              </li>
                              <li>
                                <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                                  <CheckIcon className="h-4 w-4" />
                                </span>{" "}
                                License Fee: AED {licenseFee.toLocaleString()}
                              </li>
                              <li>
                                <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-50 text-black">
                                  <CheckIcon className="h-4 w-4" />
                                </span>
                                {establishmentCard
                                  ? ` Establishment Card: AED ${establishmentCard.toLocaleString()}`
                                  : " Establishment Card: N/A"}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-md bg-primary-white px-4 py-5 sm:px-6">
                      <div className="flex items-center">
                        <DocumentTextIcon
                          className="mr-2 h-6 w-6 text-body text-primary-default"
                          aria-hidden="true"
                        />
                        <span className="text-body leading-6 text-primary-default">
                          Add ons
                        </span>
                      </div>
                      <div className="mt-4">
                        {Object.entries(company.addons).map(([key, addon]) => {
                          if (addon.selected) {
                            const { mainService, package: pkg } = addon;
                            const serviceDetails = services[mainService][pkg];
                            return (
                              <div
                                key={key}
                                className="mb-4 overflow-hidden rounded-lg bg-white shadow"
                              >
                                <div className="bg-[#144EFF] p-4">
                                  <h3 className="text-lg font-semibold text-white">
                                    {serviceDetails.title}{" "}
                                    {addon.vip && (
                                      <span className="ml-2 inline-block rounded-full bg-amber-600 px-3 py-1 text-xs font-medium text-white">
                                        VIP
                                      </span>
                                    )}
                                  </h3>
                                  <p className="text-sm text-gray-200">
                                    {serviceDetails.description}
                                  </p>
                                </div>
                                <div className="border-t border-gray-200 bg-white px-4 py-3">
                                  <div className="grid grid-cols-3 gap-4">
                                    <div className="text-md font-medium">
                                      <span className="text-gray-800">
                                        Quantity:
                                      </span>
                                      <span className="ml-1 text-primary-default">
                                        {addon.quantity}
                                      </span>
                                    </div>
                                    <div className="text-md font-medium">
                                      <span className="text-gray-800">
                                        Add-on Cost:
                                      </span>
                                      <span className="ml-1 text-primary-default">
                                        AED {addon.cost.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="text-md font-medium">
                                      <span className="text-gray-800">
                                        Total Cost:
                                      </span>
                                      <span className="ml-1 text-primary-default">
                                        AED{" "}
                                        {(
                                          addon.quantity * addon.cost
                                        ).toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>

                    {/* Pricing Details Section */}
                    <div className="border-t border-gray-200 bg-slate-100 px-4 py-5 sm:p-6">
                      <div className="flex items-center">
                        <span className="text-body leading-6 text-primary-default">
                          Cost Breakdown
                        </span>
                      </div>
                      <dl className="mt-4 space-y-4">
                        <div className="flex justify-between">
                          <dt className="text-body text-primary-dark">
                            License Fee
                          </dt>
                          <dd className="text-body text-primary-dark">
                            {licenseFee.toLocaleString()} AED
                          </dd>
                        </div>
                        <div className="flex justify-between">
                          <dt className="text-body text-primary-dark">
                            License Establishment Card
                          </dt>
                          <dd className="text-body text-primary-dark">
                            {establishmentCard.toLocaleString()} AED
                          </dd>
                        </div>
                        <div className="flex justify-between">
                          <dt className="text-body text-primary-dark">
                            Visa Fee (x{company.visas?.visa || 0})
                          </dt>
                          <dd className="text-body text-primary-dark">
                            {(
                              (company.visas?.visaFee || 0) *
                              (company.visas?.visa || 0)
                            ).toLocaleString()}{" "}
                            AED
                          </dd>
                        </div>
                        {extraActivityCost > 0 && (
                          <div className="flex justify-between">
                            <dt className="text-body text-primary-dark">
                              Additional Activity Cost
                            </dt>
                            <dd className="text-body text-primary-dark">
                              {extraActivityCost.toLocaleString()} AED
                            </dd>
                          </div>
                        )}
                        {Object.entries(company.addons || {}).map(
                          ([key, addon]) =>
                            addon.selected && (
                              <div className="flex justify-between" key={key}>
                                <dt className="text-body text-primary-dark">{`${
                                  services[addon.mainService][addon.package]
                                    .title
                                } (${addon.quantity}x)`}</dt>
                                <dd className="text-body text-primary-dark">
                                  {addon.cost.toLocaleString()} AED
                                </dd>
                              </div>
                            )
                        )}
                        <div className="flex justify-between">
                          <dt className="text-body text-primary-dark">
                            Service Fee
                          </dt>
                          <dd className="text-body text-primary-dark">
                            {0} AED
                          </dd>
                        </div>
                        <div className="flex justify-between border-t border-gray-200 pt-4">
                          <dt className="text-smsubheading font-semibold text-primary-default">
                            Total
                          </dt>
                          <dd className="text-smsubheading font-semibold text-primary-default">
                            {totalPrice.toLocaleString()} AED
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyDetailsPage;
