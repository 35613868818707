import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  EyeIcon,
  XMarkIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import RichTextEditor from "./RichTextEditor";
import ImageUploader from "./ImageUploader";
import { Link } from "react-router-dom";
import Header from "./Header";

export const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentBlog, setCurrentBlog] = useState({
    id: null,
    title: "",
    content: "",
    image: null,
    altText: "",
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loadingState, setLoadingState] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const originalBlog = useRef({});

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    setLoadingState({ ...loadingState, fetch: true });
    try {
      const response = await axios.get(
        "https://api.instacorp.ae/api/blog",
        {
          headers: getAuthHeader(),
        }
      );
      setBlogs(response.data);
    } catch (error) {
      console.error("Fetch blogs error:", error);
    } finally {
      setLoadingState({ ...loadingState, fetch: false });
    }
  };

  const createOrUpdateBlog = async (formData, id) => {
    setLoadingState({ ...loadingState, submit: true });
    setIsSubmitting(true);

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    try {
      if (id) {
        // Only call update API if there are changes
        if (
          JSON.stringify(originalBlog.current) !==
          JSON.stringify({
            title: currentBlog.title,
            content: currentBlog.content,
          })
        ) {
          await axios.put(
            `https://api.instacorp.ae/api/blog/${id}`,
            formData,
            {
              headers: getAuthHeader(),
            }
          );
        }
      } else {
        await axios.post(
          "https://api.instacorp.ae/api/blog",
          formData,
          {
            headers: getAuthHeader(),
          }
        );
      }
      await fetchBlogs(); // Refresh the blog list
    } catch (error) {
      console.error("Error submitting blog:", error);
    } finally {
      setIsSubmitting(false);
      setIsFormOpen(false); // Close the modal
    }
  };

  const deleteBlog = async (id) => {
    setLoadingState({ ...loadingState, [id]: true });

    try {
      await axios.delete(`https://api.instacorp.ae/api/blog/${id}`, {
        headers: getAuthHeader(),
      });
      await fetchBlogs(); // Refresh blogs after successful deletion
    } catch (error) {
      console.error("Delete blog error:", error);
    } finally {
      setLoadingState({ ...loadingState, [id]: false });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", currentBlog.title);
    formData.append("content", currentBlog.content);
    formData.append("image", currentBlog.image); // Assuming image is already a File object
    formData.append("altText", currentBlog.altText);

    await createOrUpdateBlog(formData, currentBlog.id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentBlog({ ...currentBlog, [name]: value });
  };

  const handleImageChange = (imageData) => {
    setCurrentBlog({
      ...currentBlog,
      image: imageData,
      altText: imageData.altText,
    });
  };

  const handleEditorChange = (content) => {
    setCurrentBlog({ ...currentBlog, content });
  };

  const resetForm = () => {
    setCurrentBlog({ title: "", content: "", image: "", altText: "" });
    setIsFormOpen(false);
  };

  const handleEditClick = (blog) => {
    setCurrentBlog({
      id: blog._id,
      title: blog.title,
      content: blog.content,
      image: blog.image,
      altText: blog.altText,
    });
    originalBlog.current = { title: blog.title, content: blog.content };
    setIsFormOpen(true);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-4xl font-bold text-gray-800">Blog Dashboard</h1>
          <button
            onClick={() => setIsFormOpen(true)}
            className="bg-blue-600 text-white px-5 py-2 rounded-lg flex items-center shadow-lg hover:bg-blue-700 transition-colors focus:outline-none"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            New Post
          </button>
        </div>

        {loadingState.fetch ? (
          <div className="text-center text-indigo-600">Loading...</div>
        ) : (
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {blogs.map((blog) => (
              <div
                key={blog._id}
                className="bg-white p-4 rounded-lg shadow flex flex-col"
              >
                <Link to={`/blog/${blog._id}`} key={blog._id}>
                  <img
                    src={blog.imageUrl}
                    alt={blog.imageAltText || "Blog Image"}
                    className="rounded-lg mb-4 h-60 w-full object-cover"
                  />
                </Link>
                <h2 className="text-xl font-semibold mb-2 cursor-pointer">
                  {blog.title}
                </h2>
                <div
                  className="text-gray-600 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: blog.content.substring(0, 100) + "...",
                  }}
                />

                <div className="mt-auto flex justify-between items-center">
                  <button
                    onClick={() => {
                      setCurrentBlog(blog);
                      setIsFormOpen(true);
                    }}
                    className="text-gray-600 hover:text-gray-900"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => deleteBlog(blog._id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {isFormOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4">
            <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">
                  {currentBlog._id ? "Edit Blog" : "Create Blog"}
                </h2>
                <button
                  onClick={() => setIsFormOpen(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label
                    htmlFor="title"
                    className="text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={currentBlog.title}
                    onChange={handleInputChange}
                    placeholder="Enter the title"
                    required
                    className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label
                    htmlFor="content"
                    className="text-sm font-medium text-gray-700"
                  >
                    Content
                  </label>
                  <RichTextEditor
                    value={currentBlog.content}
                    onChange={handleEditorChange}
                  />
                </div>
                <div>
                  <ImageUploader onImageChange={handleImageChange} />
                </div>

                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={resetForm}
                    className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                  >
                    {currentBlog._id ? "Update Post" : "Create Post"}
                  </button>
                </div>
              </form>
              {currentBlog.image && (
                <div className="border-2 border-dashed border-gray-300 rounded p-4 my-4">
                  <img
                    src={URL.createObjectURL(currentBlog.image)}
                    alt="Preview"
                    className="rounded max-h-40 mx-auto"
                  />
                </div>
              )}
              {isSubmitting && (
                <div className="absolute inset-0 flex justify-center items-center">
                  <div className="loader" />
                  Loading
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {loadingState.submit && (
        <div className="text-center text-indigo-600">Submitting...</div>
      )}
    </div>
  );
};
export default Blog;
